@import 'styles/utils/mixins';

.text {
  @include flex(row, center, center, wrap);

  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  & > div {
    width: 100%;
  }

  h1 {
    @include horizontal-mobile {
      font-size: 2.3rem;
    }

    user-select: none;
    font-family: $main-title-font;
    font-size: 5rem;
    font-weight: 900;
    color: $white;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 3px;
    will-change: transform;
    width: 100%;

    span {
      opacity: 0;
    }

    @include tablet {
      font-size: 3.8rem;
    }

    @include phone {
      @include fontSize(26px);

      padding: 5px;
      color: $white !important;
      text-shadow: none !important;
      opacity: 1;
      width: calc(100% - 10px);
    }

    @include phone-height-500 {
      transform: translateY(-120px);
    }

    @include phone-height-600 {
      @include iOSDevice {
        transform: translateY(-180px);
      }
    }

    @include phone-height-650 {
      @include iOSDevice {
        transform: translateY(-210px);
      }

      transform: translateY(-150px);
    }

    @include phone-height-730 {
      @include iOSDevice {
        transform: translateY(-240px);
      }

      transform: translateY(-180px);
    }

    @include phone-height-800 {
      @include iOSDevice {
        transform: translateY(-290px);
      }

      transform: translateY(-240px);
    }
  }

  @include phone {
    align-items: flex-end;
    width: 100%;
  }
}

.slogan {
  white-space: pre-wrap;
}

.animate {
  will-change: transform;
  transform: translateY(0) !important;
  opacity: 1 !important;
}
